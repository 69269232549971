import styled from 'styled-components';
import SearchIcon from './icons/SearchIcon';
import React, { FC, useEffect, useRef, useState } from 'react';
import TextInput from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { gql } from '@apollo/client';
import { createPortal } from 'react-dom';
import { useSearchFieldLazyQuery } from '../generated/graphql';
import { useDebounce } from 'use-debounce';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Flex } from '@rebass/grid';
import Link from './Link';
import Spinner from './icons/Spinner';
import CaretRight from './icons/CaretRight';
import { useRouter } from 'next/router';
import Img from '@oberoninternal/travelbase-website/dist/components/Img';

import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { tileTransformToProps } from '../constants/imageTransforms';
import useOnClickOutside from 'use-onclickoutside';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';

interface Props {
    menuIsOpen: boolean;
    searchLink?: string;
    setLogoIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const fragment = gql`
    query SearchField($locale: String!, $q: String!) {
        globalSearch(language: $locale, query: $q, limit: 50) {
            totalHits
            hits {
                ...SearchHit
            }
        }
    }
`;

const SearchField: FC<React.PropsWithChildren<Props>> = ({ menuIsOpen, searchLink, setLogoIsExpanded }) => {
    const [hasFocus, setHasFocus] = useState(false);
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
    const [overlayContainer, setOverlayContainer] = useState<HTMLDivElement | null>(null);
    const [fetch, { data, loading }] = useSearchFieldLazyQuery();
    const { locale, formatDate } = useIntl();
    const { query: params } = useRouter();
    const [queryValue, setQueryValue] = useState(params.q);
    const [query, { flush }] = useDebounce(queryValue, 400);
    const wrapRef = useRef<HTMLFormElement | null>(null);
    useOnClickOutside(wrapRef, () => setHasFocus(false));
    const { formatMessage } = useIntl();
    const deviceSize = useDeviceSize();

    // fetch search results
    useEffect(() => {
        if (query && hasFocus) {
            fetch({
                variables: {
                    q: query as string,
                    locale,
                },
            });
        }
    }, [fetch, hasFocus, locale, query]);

    // creates/destroys overlay container
    useEffect(() => {
        const container = document.createElement('div');
        document.body.append(container);
        setOverlayContainer(container);

        return () => {
            container.remove();
            setOverlayContainer(null);
        };
    }, []);

    // Hides the Expanded logo if searchbar is opened
    useEffect(() => {
        if (hasFocus) {
            setLogoIsExpanded(false);
        } else {
            setLogoIsExpanded(true);
        }
    }, [hasFocus, setLogoIsExpanded]);

    return (
        <>
            <Wrap hasFocus={hasFocus}>
                {hasFocus && (
                    <CloseButton onClick={() => setHasFocus(false)}>
                        <CaretRight />
                    </CloseButton>
                )}
                <FormWrap
                    ref={wrapRef}
                    open={menuIsOpen}
                    onSubmit={async e => {
                        e.preventDefault();
                        const val = inputRef.current?.value ?? '';

                        setQueryValue(val);
                        flush();
                    }}
                >
                    <TextWrap hasFocus={hasFocus}>
                        <StyledTextInput
                            id="search"
                            placeholder={formatMessage({ defaultMessage: 'Zoeken' })}
                            ref={inputRef}
                            value={queryValue}
                            onChange={e => setQueryValue(e.currentTarget.value)}
                            onFocus={() => setHasFocus(true)}
                            autoComplete="off"
                        />
                        {query && data?.globalSearch && (
                            <QuickResults>
                                {data?.globalSearch.hits?.length === 0 && (
                                    <Box py={3} px={5}>
                                        <Body>
                                            <strong>
                                                <FormattedMessage defaultMessage="Geen resultaten gevonden" />
                                            </strong>
                                        </Body>
                                        <Body variant="small">
                                            <FormattedMessage defaultMessage="Probeer uw zoekopdracht aan te passen." />
                                        </Body>
                                    </Box>
                                )}
                                {data?.globalSearch.hits
                                    ?.slice(...(deviceSize === 'mobile' ? [0, 7] : [0]))
                                    .map(result => {
                                        if (!result) {
                                            return null;
                                        }

                                        const { image } = result;
                                        return (
                                            <QuickResult key={result.uri}>
                                                <Link href={result.uri ?? ''}>
                                                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                                    <a onClick={() => setHasFocus(false)}>
                                                        <Box py={3} px={5}>
                                                            <Flex alignItems="center">
                                                                {image && (
                                                                    <ImageContainer mr={4}>
                                                                        <Img
                                                                            {...tileTransformToProps(
                                                                                {
                                                                                    ...image,
                                                                                    __typename: 'library_Asset',
                                                                                    placeholderUrl:
                                                                                        image.placeholderUrl,
                                                                                    srcset: image.srcset,
                                                                                    srcsetWebp: image.srcsetWebp,
                                                                                    extension: image.extension ?? '',
                                                                                },
                                                                                '100%'
                                                                            )}
                                                                        />
                                                                    </ImageContainer>
                                                                )}

                                                                <StyledBox>
                                                                    {result.postDate && result.type === 'articles' && (
                                                                        <Body variant="small">
                                                                            {formatDate(new Date(result.postDate), {
                                                                                // @ts-ignore
                                                                                dateStyle: 'long',
                                                                            })}
                                                                        </Body>
                                                                    )}
                                                                    <Title variant="tiny">{result.title}</Title>
                                                                </StyledBox>
                                                            </Flex>
                                                        </Box>
                                                    </a>
                                                </Link>
                                            </QuickResult>
                                        );
                                    })}
                                <ButtonWrapper>
                                    <Box px={5} mt={7}>
                                        <Divider />
                                    </Box>

                                    {data?.globalSearch.hits && data.globalSearch.hits.length > 0 && (
                                        <ButtonWrapper pt={6} pb={7} px={5}>
                                            <Link
                                                href={{
                                                    pathname: `/${searchLink}`,
                                                    query: {
                                                        q: queryValue,
                                                    },
                                                }}
                                            >
                                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                                <a onClick={() => setHasFocus(false)}>
                                                    <Button size="large">
                                                        <FormattedMessage defaultMessage="Bekijk alle resultaten" />
                                                    </Button>
                                                </a>
                                            </Link>
                                        </ButtonWrapper>
                                    )}
                                </ButtonWrapper>
                            </QuickResults>
                        )}
                    </TextWrap>
                    {hasFocus && loading && (
                        <Searching>
                            <IsSearchingText>
                                <FormattedMessage defaultMessage="Zoeken" />
                                {'...'}
                            </IsSearchingText>
                            <StyledSpinner />
                        </Searching>
                    )}
                    <SearchButton
                        type="submit"
                        hasFocus={hasFocus}
                        aria-label={formatMessage({ defaultMessage: 'Zoeken' })}
                        onClick={e => {
                            if (!hasFocus) {
                                inputRef.current?.focus();
                                e.preventDefault();
                            }
                        }}
                    >
                        <StyledSearchIcon />
                    </SearchButton>
                </FormWrap>
            </Wrap>
            {overlayContainer && createPortal(<Overlay hasFocus={hasFocus} />, overlayContainer)}
        </>
    );
};

export default SearchField;

const ButtonWrapper = styled(Box)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        position: sticky;
        bottom: 0;
    }
    background: ${({ theme }) => theme.colors.neutral[0]};
`;

const ImageContainer = styled(Box)`
    width: 6.4rem;
    height: 4.5rem;
    position: relative;
    border-radius: 0.6rem;
    overflow: hidden;
`;

const FormWrap = styled.form<{ open: boolean }>`
    align-items: center;
    display: ${({ open }) => (!open ? 'flex' : 'none')};
`;

const Wrap = styled.div<{ hasFocus: boolean }>`
    height: auto;
    align-items: center;
    display: flex;
    position: ${({ hasFocus }) => (hasFocus ? 'absolute' : 'relative')};
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.colors.neutral[0]};
    padding: ${({ hasFocus }) => (hasFocus ? '0.8rem 1.6rem' : '0')};
    justify-content: space-between;
    margin-left: ${({ hasFocus }) => (hasFocus ? '0' : '0.8rem')} !important;
    margin-left: 0;
    z-index: 10;
    min-width: 4.4rem;
    width: ${({ hasFocus }) => (hasFocus ? 'auto' : '4.4rem')} !important;
    transition: background 0.1s ease-in-out, color 0.1 ease-in-out;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        display: flex;
        margin: 0;
        height: 100%;
    }
`;

const IsSearchingText = styled.h4`
    height: 2.4rem;
    color: ${({ theme }) => theme.colors.neutral[50]};
    padding-left: 1rem;
    padding-right: 1rem;
`;

const Searching = styled.div`
    position: absolute;
    height: 2.4rem;
    left: auto;
    right: 8.4rem;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledSpinner = styled(Spinner)`
    width: 2.4rem;
    height: 2.4rem;
    color: ${({ theme }) => theme.colors.neutral[50]};
`;

const Overlay = styled.div<{ hasFocus: boolean }>`
    opacity: ${({ hasFocus }) => (hasFocus ? 0.56 : 0)};
    pointer-events: ${({ hasFocus }) => (hasFocus ? 'unset' : 'none')};
    transition: opacity 0.25s;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.neutral[80]};
    z-index: ${({ theme }) => theme.zIndices.overlay};
`;

const CloseButton = styled.button`
    appearance: none;
    background: inherit;
    color: inherit;
    margin-right: 1.2rem;

    svg {
        transform: rotateY(180deg);
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        margin-left: 16rem;
    }
`;

const TextWrap = styled.div<{ hasFocus: boolean }>`
    position: absolute;
    right: 1.6rem;
    bottom: 0.8rem;
    top: 0.8rem;
    left: 5.2rem;
    pointer-events: ${({ hasFocus }) => (hasFocus ? 'inherit' : 'none')};
    opacity: ${({ hasFocus }) => +hasFocus};
    transition: all 0.25s;
    z-index: 1;
    opacity: ${({ hasFocus }) => (hasFocus ? 'auto' : 0)};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        left: ${({ hasFocus }) => (hasFocus ? '22rem' : '5.2rem')};
        height: 5.2rem;
        margin: auto;
    }

    > div {
        height: 100%;
    }
`;

const QuickResults = styled.ul`
    position: fixed;
    left: 0;
    right: 0;
    top: 100%;
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(59, 118, 160, 0.02), 0 4px 12px 0 rgba(59, 118, 160, 0.04);
    padding: 1.2rem 0 0 0;
    height: calc(100vh - 7.2rem);
    background: ${({ theme }) => theme.colors.neutral[0]};
    overflow: auto;
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        padding-bottom: env(safe-area-inset-bottom);
    }
`;

const Divider = styled.div`
    height: 1px;
    border: 1px solid ${({ theme }) => theme.colors.neutral[20]};
`;

const QuickResult = styled.li`
    h4 {
        color: inherit;
    }

    a {
        text-decoration: none !important;
        color: inherit !important;
    }
`;

const SearchButton = styled.button<{ hasFocus: boolean }>`
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 50%;
    background: ${({ theme, hasFocus }) => (hasFocus ? theme.colors.primary[60] : theme.colors.neutral[0])};
    color: ${({ hasFocus, theme }) => (hasFocus ? theme.colors.neutral[0] : 'inherit')};
    right: ${({ hasFocus }) => (hasFocus ? '0.6rem' : '0')};
    transition: 0.25s color, 0.25s background, 0.15s right;
    backface-visibility: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    padding: 0;

    :hover {
        background: ${({ theme, hasFocus }) => (hasFocus ? theme.colors.primary[80] : 'transparent')};
    }
`;

const StyledTextInput = styled(TextInput)`
    height: 100%;
    border-radius: 2.8rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    box-shadow: 0 2px 4px 0 rgba(59, 118, 160, 0.02), 0 4px 12px 0 rgba(59, 118, 160, 0.04),
        0 0 0 1px var(--border-color), inset 0 0 0 1px var(--border-color);

    :focus,
    :hover:not(:focus) {
        --border-color: ${({ theme }) => theme.colors.neutral[20]};
    }
`;

const StyledSearchIcon = styled(SearchIcon)`
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.2rem;
`;

const StyledBox = styled(Box)`
    width: calc(100% - 8rem);
`;
