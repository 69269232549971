import React, { FC, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import HamburgerMenu from './HamburgerMenu';
import Link from './Link';
import MainNavigation, { EntryIds, MenuItemPageFragment } from './MainNavigation';
import Logo from './icons/Logo';
import { MenuItemPageFieldsFragment, MenuItemsFragment, PageFragment } from '../generated/graphql';
import ExpandedNavigation from './ExpandedNavigation';
import Page from '@oberoninternal/travelbase-website/dist/components/Page';
import useRouterEvent from '@oberoninternal/travelbase-website/dist/hooks/useRouterEvent';
import { transparentize } from 'polished';
import { useRouter } from 'next/router';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import SearchBox from './SearchBox';
import { FormattedMessage, useIntl } from 'react-intl';
import useIsTop from '../hooks/useIsTop';
import SearchField from './SearchField';
import LangSwitch from './LangSwitch';
import TertiaryButton from './TertiaryButton';
import NavWrapper from './layout/NavWrapper';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import UserIcon from './icons/UserIcon';
import { isContactLink } from '../utils/getContactLink';
import StoreIcon from './icons/StoreIcon';

interface Props extends Pick<PageFragment, 'localized'> {
    menu: MenuItemsFragment[];
    searchLink?: string;
    forceSmallLogo?: boolean;
}

const traverse = (
    item: MenuItemPageFragment,
    needles: EntryIds,
    level: number,
    initial: MenuItemPageFieldsFragment[]
): MenuItemPageFieldsFragment[] => {
    const needle = needles[level];
    const selected =
        // me or my children
        item.id === needle
            ? item
            : item.children?.find(child => child?.__typename === 'page_Entry' && child.id === needle);
    if (selected?.__typename === 'page_Entry' && 'children' in selected) {
        return traverse(selected as MenuItemPageFragment, needles, level + 1, [...initial, selected]);
    }
    return initial;
};

const Header: FC<React.PropsWithChildren<Props>> = ({ menu, localized, searchLink, forceSmallLogo }: Props) => {
    const [open, setOpen] = useState(false);
    const [logoIsExpanded, setLogoIsExpanded] = useState(true);
    const { asPath } = useRouter();
    const { locale } = useIntl();
    const [selectedPageIds, setSelectedPageIds] = useState<EntryIds>([]);
    const selectedItem = useMemo(
        () =>
            menu?.find(item => {
                const itemPage = item.menuLink?.[0]?.element;
                return itemPage?.__typename === 'page_Entry' && itemPage.id === selectedPageIds[0];
            }),
        [menu, selectedPageIds]
    );

    const selectedPages = useMemo(() => {
        const selectedPage = selectedItem?.menuLink?.[0]?.element;
        if (selectedPage?.__typename !== 'page_Entry') {
            return [];
        }
        return traverse(selectedPage, selectedPageIds, 0, []);
    }, [selectedItem, selectedPageIds]);
    const { open: searchModalOpen, onOpen: onOpenModal, onClose } = useSesame(false);
    const isTop = useIsTop() && selectedPageIds.length === 0 && !forceSmallLogo && logoIsExpanded;

    const handleRouteChange = useCallback(() => setOpen(false), []);
    useRouterEvent('routeChangeStart', handleRouteChange);

    const hideButtonPages = [
        'checkout/extras/',
        'checkout/details/',
        'checkout/payment/',
        '/accommodatie/',
        '/accommodation/',
        '/unterkunft/',
        '/overnachten/',
        '/search/',
        '/uebernachten/',
        '/spend-the-night/',
        '/activity',
        '/activiteit',
        '/aktivitat',
        '/agenda',
        '/whats-on',
        '/veranstaltungsuebersicht',
    ];
    const homePagePath = '/';
    const hideButton = hideButtonPages.some(hideButtonPage => asPath.includes(hideButtonPage));
    const hideButtonHome = homePagePath === asPath;
    const deviceSize = useDeviceSize();
    return (
        <>
            <HeaderWrapper
                onMouseLeave={() => {
                    setSelectedPageIds([]);
                    setOpen(false);
                }}
            >
                <StyledPage>
                    <StyledNavWrapper>
                        <LogoWrapper>
                            <Link href={`/`} passHref>
                                <LogoLink isTop={isTop}>
                                    <BrandingImage
                                        src={'/static/img/vvvtexel-land-alt.svg'}
                                        alt="VVV Texel"
                                        isTop={isTop}
                                        width={64}
                                        height={114}
                                    />
                                    <StyledLogo isTop={isTop} />
                                </LogoLink>
                            </Link>
                        </LogoWrapper>
                        <MainNavigation
                            open={open}
                            setOpen={setOpen}
                            menu={menu}
                            selectedPageIds={selectedPageIds}
                            setSelectedPageIds={setSelectedPageIds}
                        />
                        {deviceSize !== 'mobile' && (
                            <SearchField
                                menuIsOpen={open}
                                searchLink={searchLink}
                                setLogoIsExpanded={setLogoIsExpanded}
                            />
                        )}
                        <IconsWrapper
                            onMouseEnter={() => {
                                setSelectedPageIds([]);
                                setOpen(false);
                            }}
                        >
                            <Icons open={open}>
                                <LangSwitch open={open} localized={localized} />
                                {!hideButton && !hideButtonHome && (
                                    <>
                                        <StyledButton
                                            className="lt-xl"
                                            rounded
                                            size="large"
                                            onClick={onOpenModal}
                                            menuIsOpen={open}
                                        >
                                            <span>
                                                <FormattedMessage defaultMessage="Boeken" />
                                            </span>
                                        </StyledButton>
                                        <StyledButton
                                            className="gt-xl"
                                            rounded
                                            size="large"
                                            onClick={onOpenModal}
                                            menuIsOpen={open}
                                        >
                                            <span>
                                                <FormattedMessage defaultMessage="Zoek en Boek" />
                                            </span>
                                        </StyledButton>
                                    </>
                                )}
                                {deviceSize === 'mobile' && (
                                    <SearchField
                                        menuIsOpen={open}
                                        searchLink={searchLink}
                                        setLogoIsExpanded={setLogoIsExpanded}
                                    />
                                )}
                                <Link href={`/my`}>
                                    <StyledMyButton variant="outline" size="large">
                                        <UserIcon />
                                        <TextAnchor>
                                            <FormattedMessage defaultMessage="Mijn Texel" />
                                        </TextAnchor>
                                    </StyledMyButton>
                                </Link>
                                <StyledWebshopButton
                                    variant="outline"
                                    size="large"
                                    href={`https://shop.texel.net/${locale === 'nl' ? '' : locale}`}
                                    target="_blank"
                                    as="a"
                                >
                                    <StoreIcon />
                                    <span className="gt-s">
                                        <FormattedMessage defaultMessage="Webshop" />
                                    </span>
                                </StyledWebshopButton>

                                <StyledHamburgerMenu open={open} openMenu={setOpen} />
                            </Icons>
                        </IconsWrapper>
                    </StyledNavWrapper>
                </StyledPage>

                <ExpandedNavigation
                    selectedPages={selectedPages as MenuItemPageFragment[]}
                    setSelectedPageIds={setSelectedPageIds}
                    menu={menu}
                    open={open}
                    setOpen={setOpen}
                />
            </HeaderWrapper>
            <Overlay
                isActive={open}
                onClick={() => {
                    setOpen(false);
                    setSelectedPageIds([]);
                }}
            />
            <SearchBox onClose={onClose} open={searchModalOpen} />
        </>
    );
};

export default Header;

export const isActiveMenuItem = (asPath: string, uri: string): boolean => {
    // Retrieve current path without first and trailing slash
    const currentPath = asPath.substring(1, asPath.length - 1);

    // Only applicable to highlight contact menu if current page is 'Contact'
    if (currentPath === uri) {
        return true;
    }

    // Parent of 'Contact' entry is 'About us'. Do not highlight 'About us' menu if contact page is active
    const contactIsInPath = isContactLink(currentPath);

    // Highlight navigation item in menu if active
    const highlightItem = currentPath.includes(uri) && !contactIsInPath;

    return highlightItem;
};

export const MenuItemTitle = styled.strong<{ highlight: boolean }>`
    color: ${({ theme, highlight }) => (highlight ? theme.colors.secondary['20'] : theme.colors.primary['80'])};
`;

const TextAnchor = styled.a`
    @media only screen and (max-width: 1320px) and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        font-size: 1.2rem;
    }
`;

const Overlay = styled.div<{ isActive: boolean }>`
    opacity: ${({ isActive }) => (isActive ? 0.56 : 0)};
    pointer-events: ${({ isActive }) => (isActive ? 'unset' : 'none')};
    transition: opacity 0.25s;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.neutral[80]};
    z-index: ${({ theme }) => theme.zIndices.overlay};
`;

const StyledButton = styled(TertiaryButton)<{ menuIsOpen: boolean }>`
    white-space: nowrap;
    padding: 0 1.6rem;
    height: 3.8rem;
    @media only screen and (max-width: ${({ theme }) => theme.mediaQueries.xl}) and (min-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        padding: 0 1rem;
    }
    @media screen and (max-width: calc(${({ theme }) => theme.mediaQueries.l} - 1px)) {
        display: ${({ menuIsOpen }) => (menuIsOpen ? 'none' : 'inherit')};
        height: 3.2rem;
    }
`;

const StyledPage = styled(Page as FC<React.PropsWithChildren<unknown>>)`
    background: ${({ theme }) => theme.colors.neutral[0]};
    box-shadow: 0 2px 4px 0 ${({ theme }) => transparentize(0.98, theme.colors.neutral[80])},
        0 4px 12px 0 ${({ theme }) => transparentize(0.96, theme.colors.neutral[80])};
    z-index: 1;
    position: relative;
    @media screen and (max-width: calc(${({ theme }) => theme.mediaQueries.l} - 1px)) {
        &:after {
            content: '';
            width: 100%;
            position: absolute;
            left: 0;
            height: 0.4rem;
            top: calc(100% - 0.4rem);
            box-shadow: 0px 2px 4px 0 ${({ theme }) => transparentize(0.98, theme.colors.neutral[80])},
                inset 0px -1px 0px #ebedee;
            z-index: ${({ theme }) => theme.zIndices.overlay + 2};
            mix-blend-mode: multiply;
        }
    }
`;

const StyledNavWrapper = styled(NavWrapper as FC<React.PropsWithChildren<unknown>>)`
    align-items: center;
    background: ${({ theme }) => theme.colors.neutral['0']};
    min-height: 10.3rem;
    display: grid;
    grid-template-columns: 0fr min-content 1fr 4fr;

    @media screen and (max-width: calc(${({ theme }) => theme.mediaQueries.l} - 1px)) {
        display: flex;
        justify-content: space-between;
        min-height: 7.2rem;
    }
`;

const HeaderWrapper = styled.header`
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(0);
    transition: transform 200ms ease-in-out;
    position: sticky;
    z-index: ${({ theme }) => theme.zIndices.overlay + 1};

    a {
        color: ${({ theme }) => theme.colors.primary['80']};
        text-decoration: none;
    }

    strong {
        font-weight: bold;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        align-items: center;
        padding: 0;
        margin-bottom: 0;

        a {
            &:hover,
            &:focus {
                color: ${({ theme }) => theme.colors.primary['60']};
            }
        }
    }
`;

const LogoWrapper = styled.div`
    max-width: 10rem;
    width: 100%;
    cursor: pointer;
    text-align: center;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        max-width: none;
    }
`;

const IconsWrapper = styled.div``;

const Icons = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: row;
    gap: 1.6rem;

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.l}) {
        gap: 0.8rem;
    }

    transition: color 0.5s ease-in-out;
    align-items: center;
    justify-content: flex-end;
    height: 4rem;
    color: ${({ theme }) => theme.colors.primary['80']};

    ${({ open, theme }) =>
        open &&
        `
            transition: color 0.1s ease-in-out;
            color: ${theme.colors.primary['80']}
            `};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        position: relative;
        display: flex;
        width: initial;
    }

    > a {
        display: inline-flex;
    }
`;

const StyledHamburgerMenu = styled(HamburgerMenu)`
    display: unset;
    width: 4.4rem;
    text-align: right;
    margin-left: 0 !important;

    button {
        &:focus {
            outline-width: 0;
            outline-style: dashed;
            outline-color: transparent;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        display: none;
    }
`;

const StyledLogo = styled(Logo)<{ isTop: boolean }>`
    transition: color 0.3s ease-in-out;
    max-width: 11.7rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        ${({ isTop }) => isTop && `margin-bottom: 2.4rem;`}
    }
`;
const BrandingImage = styled.img<{ isTop: boolean }>`
    display: none;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        position: relative;
        display: inline-block;
        transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
        width: 6.4rem;
        margin-top: 2.4rem;
        margin-bottom: 1.6rem;
        max-height: 11.4rem;
        opacity: 0;
        transform: translateY(20%);
        ${({ isTop }) =>
            isTop &&
            css`
                opacity: 1;
                transform: translateY(0%);
            `};
    }
`;
const LogoLink = styled.a<{ isTop: boolean }>`
    color: ${({ theme }) => theme.colors.primary[40]} !important;
    display: block;
    position: relative;
    top: 0.2rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        background-color: ${({ theme }) => theme.colors.primary[40]};
        color: ${({ theme }) => theme.colors.neutral[0]} !important;
        width: 15.3rem;
        transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, background-color 0.5s ease-in-out;
        box-shadow: 0 2px 4px 0 rgba(59, 118, 160, 0.02), 0 4px 12px 0 rgba(59, 118, 160, 0.04);
        padding: 0 2.4rem;
        height: 20.6rem;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 999;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0 0 6px;
        ${({ isTop, theme }) =>
            !isTop &&
            css`
                transform: translateY(-12rem);
                box-shadow: none;
                background-color: ${theme.colors.neutral[0]};
                color: ${theme.colors.primary[40]} !important;
            `};
    }
`;

const StyledMyButton = styled(Button)`
    height: 3.6rem;
    padding: 0.4rem 0.8rem;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 1.8rem;
        height: 1.8rem;
    }
    & :hover {
        outline: none;
        color: ${({ theme }) => theme.colors.primary[60]};
        box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.colors.neutral[30]};
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding: 0.4rem 1.6rem;
        box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.colors.neutral[20]}, 0 2px 4px 0 rgba(59, 118, 160, 0.02),
            0 4px 12px 0 rgba(59, 118, 160, 0.04);
        border-radius: ${({ theme }) => theme.radius.button};
        font-family: ${({ theme }) => theme.fontFamily.title};
        font-weight: 800;
    }
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        --border-color: none;

        a {
            display: none;
        }

        & :hover {
            box-shadow: none;
        }
    }
`;

const StyledWebshopButton = styled(Button)`
    height: 3.6rem;
    padding: 0.8rem;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-right: 0.8rem;

        padding: 0.4rem 1.6rem;
        box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.colors.neutral[20]}, 0 2px 4px 0 rgba(59, 118, 160, 0.02),
            0 4px 12px 0 rgba(59, 118, 160, 0.04);
        border-radius: ${({ theme }) => theme.radius.button};
        font-family: ${({ theme }) => theme.fontFamily.title};
        font-weight: 800;
    }

    svg {
        margin-right: 0.4rem;
        width: 2rem;
        height: 2rem;
    }
    & :hover {
        color: ${({ theme }) => theme.colors.secondary[60]};
        box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.colors.neutral[30]};
    }
`;
