import 'url-polyfill';
import '@oberoninternal/travelbase-ds/dsStyles.css';
import NotFoundError from '@oberoninternal/travelbase-website/dist/entities/NotFoundError';
import TorApp from '@oberoninternal/travelbase-website/dist/components/App';
import { Locale } from '@oberoninternal/travelbase-website/dist/entities/Locale';
import brandConfig from '../constants/brandConfig';
import TenantContext from '@oberoninternal/travelbase-website/dist/context/TenantContext';
import { AppProps } from 'next/app';
import React, { FC, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import Footer from '../components/Footer';
import { PageFragment } from '../generated/graphql';
import Header from '../components/Header';
import initApolloClient from '../initApolloClient';
import { APOLLO_STATE_PROP_NAME } from '@oberoninternal/travelbase-website/dist/createApolloClient';
import ErrorBoundary from '@oberoninternal/travelbase-website/dist/components/ErrorBoundary';
import { ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import Notification from '../components/Notification';
import GlobalStyle from '../globalStyling';
import NewsletterSignup from '../components/NewsletterSignup';
import usePersonalization from '../hooks/usePersonalization';
import { GlobalProps } from '../utils/addGlobalQuery';
import sentry from '@oberoninternal/travelbase-website/dist/utils/sentry';
import Head from 'next/head';

const { Sentry } = sentry();

Sentry.init();

const requireTorMessage = require.context('@oberoninternal/travelbase-website/dist/lang/compiled', false, /\.json$/);
const requireTexelMessage = require.context('../lang/compiled/', false, /\.json$/);

export const getMessages = (locale: string) => {
    let localMessages = requireTexelMessage(`./${locale}.json`);

    if (locale === 'nl') {
        const baseMessages = requireTexelMessage(`./base.json`);
        localMessages = { ...baseMessages, ...localMessages };
    }

    const torMessages = requireTorMessage(`./${locale}.json`);
    const torBrandMessages = requireTorMessage(`./${locale}.texel.json`);

    return {
        ...torMessages,
        ...torBrandMessages,
        ...localMessages,
    };
};

const langRegex = /^\.\/([a-z]+)\.json$/;

// Get the supported languages by looking for translations in the `lang/` dir.
export const supportedLanguages = requireTorMessage
    .keys()
    .map(file => langRegex.exec(file)?.[1])
    .filter((lang): lang is Locale => !!lang)
    .filter((lang: string) => lang !== 'base')
    .reverse();

export const defaultLanguage = 'nl';

// this regex tests for possible widget paths.
export const widgetRegex = /^\/weather$/g;

interface PageProps extends GlobalProps {
    suppressCookieConsent?: boolean;
    [APOLLO_STATE_PROP_NAME]: NormalizedCacheObject;
    entry?: PageFragment;
}

interface Props extends AppProps {
    pageProps: PageProps;
}

const App: FC<React.PropsWithChildren<Props>> = props => {
    const { router, pageProps, Component } = props;
    const routerLocale = router.locale as Locale;
    const languageExists = supportedLanguages.some(code => code === routerLocale);

    if (router.query.locale && !languageExists) {
        throw new NotFoundError();
    }

    usePersonalization();

    const locale: Locale = languageExists ? routerLocale : defaultLanguage;

    const messages = getMessages(locale);
    const tenantContext = useMemo(
        () => ({
            locale,
            messages,
            supportedLanguages,
            brandConfig,
        }),
        [locale, messages]
    );
    const client = initApolloClient(locale, { initialState: pageProps[APOLLO_STATE_PROP_NAME] });
    const forceSmallLogo =
        router.pathname.startsWith(`/checkout`) ||
        router.pathname.startsWith(`/search`) ||
        // page with acco blocks...
        (pageProps.entry?.__typename === 'page_Entry' &&
            pageProps.entry.contentMatrix?.some(block => block?.__typename === 'sectionAccommodations_Entry'));

    // all this nonsense is necessary because nextJS (handily!) removes stuff out of the router.query for us! thanks next!!!
    const url = new URL(router.asPath, 'http://test');

    const isClean = !!url.searchParams.get('clean') || widgetRegex.test(router.asPath);

    if ((routerLocale as string) === 'default') {
        return <></>;
    }
    return (
        <ThemeProvider theme={brandConfig.theme}>
            <TenantContext.Provider value={tenantContext}>
                <ApolloProvider client={client}>
                    <TorApp {...props}>
                        <GlobalStyle />
                        <Head>
                            <link rel="shortcut icon" type="image/ico" href="/static/img/favicon.png" />
                            <meta property="fb:app_id" content="1373126440046576" />
                        </Head>
                        {!isClean && (
                            <>
                                {pageProps.notification && <Notification notification={pageProps.notification} />}
                                {pageProps.menu && (
                                    <Header
                                        menu={pageProps.menu}
                                        localized={pageProps.entry?.localized ?? []}
                                        searchLink={pageProps.searchLink ?? undefined}
                                        forceSmallLogo={forceSmallLogo}
                                    />
                                )}
                            </>
                        )}

                        <ErrorBoundary>
                            <Component {...(pageProps as JSX.IntrinsicAttributes)} />
                        </ErrorBoundary>

                        {!isClean && (
                            <>
                                {pageProps.footer && (
                                    <>
                                        <NewsletterSignup />
                                        <Footer footer={pageProps.footer} />
                                    </>
                                )}
                            </>
                        )}
                    </TorApp>
                </ApolloProvider>
            </TenantContext.Provider>
        </ThemeProvider>
    );
};

export default App;
