import React, {
    Dispatch,
    FC,
    HTMLAttributes,
    RefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { EntryIds, MenuItemPageFragment } from './MainNavigation';
import styled, { css } from 'styled-components';
import Page from '@oberoninternal/travelbase-website/dist/components/Page';
import Link from './Link';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';
import { Maybe, MenuItemsFragment } from '../generated/graphql';
import CaretRight from './icons/CaretRight';
import { FormattedMessage, useIntl } from 'react-intl';
import { useThemeContext } from '@oberoninternal/travelbase-ds/hooks/useThemeContext';
import BackButton from './NavButton';
import NavWrapper from './layout/NavWrapper';
import useScrollLock from '../hooks/useScrollLock';
import CrossIcon from './icons/CrossIcon';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';

interface Props {
    setOpen: Dispatch<SetStateAction<boolean>>;
    selectedPages: MenuItemPageFragment[];
    setSelectedPageIds: Dispatch<SetStateAction<EntryIds>>;
    // Not sure why typescript says they are unused...they clearly are 🤔
    // eslint-disable-next-line react/no-unused-prop-types
    menu?: MenuItemsFragment[];
    // eslint-disable-next-line react/no-unused-prop-types
    open: boolean;
}

const ExpandedNavigation: FC<React.PropsWithChildren<Props>> = props => {
    const { selectedPages, menu, open } = props;
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const menuPages = (menu?.map(item => item.menuLink?.[0]?.element) as MenuItemPageFragment[]) ?? [];
    const [isTablet, setIsTablet] = useState(false);
    const theme = useThemeContext();
    const onMediaChange = useCallback((e: MediaQueryListEvent) => setIsTablet(e.matches), []);

    useScrollLock(open);

    useEffect(() => {
        const media = window.matchMedia(`(max-width: ${theme.mediaQueries.l})`);

        setIsTablet(media.matches);

        if (media.addEventListener) {
            media.addEventListener('change', onMediaChange);
            return () => media.removeEventListener('change', onMediaChange);
        }
        // target older browsers
        media.addListener(onMediaChange);
        return () => media.removeListener(onMediaChange);
    }, [onMediaChange, theme.mediaQueries.l]);

    return (
        <Page>
            <TransitionGroup>
                {open && (
                    <CSSTransition timeout={500}>
                        <ListWrapper>
                            <StyledNavWrapper ref={scrollRef}>
                                <Container>
                                    <CloseMenuButton
                                        variant="outline"
                                        size="small"
                                        onClick={() => {
                                            props.setSelectedPageIds([]);
                                            props.setOpen(false);
                                        }}
                                    >
                                        <CrossIcon />
                                        <FormattedMessage defaultMessage="Sluiten" />
                                    </CloseMenuButton>
                                    <TopLevelList>
                                        <ExpandedNavigationList level={-1} {...props} subitems={menuPages} />
                                    </TopLevelList>
                                    {new Array(3).fill(null).map((_, level) => {
                                        const item = selectedPages[level];
                                        return (
                                            <SwitchTransition key={level}>
                                                <CSSTransition
                                                    key={item?.id ?? level}
                                                    // eslint-disable-next-line no-nested-ternary
                                                    timeout={isTablet ? 500 : level === 0 ? 0 : 500}
                                                    onExiting={(el: HTMLElement | null) =>
                                                        el &&
                                                        scrollRef.current?.scrollBy({
                                                            left: -el.getBoundingClientRect().width,
                                                            behavior: 'smooth',
                                                        })
                                                    }
                                                    onEntering={(el: HTMLElement | null) =>
                                                        el &&
                                                        scrollRef.current?.scrollBy({
                                                            left: el.getBoundingClientRect().width,
                                                            behavior: 'smooth',
                                                        })
                                                    }
                                                >
                                                    <>
                                                        {item && (
                                                            <ExpandedNavigationList
                                                                level={level}
                                                                title={item.settingsMenuTitle ?? item.title}
                                                                subitems={
                                                                    (item.children as MenuItemPageFragment[]) ?? []
                                                                }
                                                                {...props}
                                                            />
                                                        )}
                                                    </>
                                                </CSSTransition>
                                            </SwitchTransition>
                                        );
                                    })}
                                </Container>
                            </StyledNavWrapper>
                        </ListWrapper>
                    </CSSTransition>
                )}
            </TransitionGroup>
        </Page>
    );
};

const ExpandedNavigationList: FC<
    React.PropsWithChildren<
        {
            title?: Maybe<string>;
            subitems: MenuItemPageFragment[];
            level: number;
        } & Props
    >
> = ({ setSelectedPageIds, selectedPages, level, subitems, title, setOpen }) => {
    const listRef = useRef<HTMLDivElement | null>(null);
    const { formatMessage } = useIntl();
    return (
        <List level={level} ref={listRef}>
            <ListInner>
                {title && (
                    <ListHeader onClick={() => setSelectedPageIds(ids => [...ids.slice(0, level)])}>
                        <BackButton aria-label={formatMessage({ defaultMessage: 'Terug' })}>
                            <BackCaret />
                        </BackButton>

                        <ItemLink as="div">
                            <Title variant="micro">
                                <FormattedMessage defaultMessage="Terug" />
                            </Title>
                        </ItemLink>
                    </ListHeader>
                )}
                {subitems?.map((child, index) => {
                    if (!child || child.settingsMenuHidden) return null;
                    const hasAnyActiveChildren =
                        (child?.children as MenuItemPageFragment[])?.some(subChild => !subChild.settingsMenuHidden) ??
                        false;
                    const children = hasAnyActiveChildren ? child?.children ?? [] : [];
                    const hasChildren = (children?.length ?? 0) > 0;
                    const isActive = selectedPages.some(({ id }) => child?.id === id);

                    return (
                        <Item key={child.id}>
                            <Link href={`/${child.uri}`} key={index} passHref>
                                <ItemLink
                                    isActive={isActive}
                                    onClick={() => {
                                        setSelectedPageIds([]);
                                        setOpen(false);
                                    }}
                                >
                                    <Title variant="micro">{child.settingsMenuTitle ?? child.title}</Title>
                                </ItemLink>
                            </Link>
                            {hasChildren && (
                                <BackButton
                                    onClick={() => {
                                        const { id } = child;
                                        if (id) {
                                            setSelectedPageIds(ids => {
                                                const newIds = [...ids.slice(0, level + 1)];

                                                if (!isActive) {
                                                    newIds.push(id);
                                                }

                                                return newIds;
                                            });
                                        }
                                    }}
                                >
                                    <StyledCaret />
                                </BackButton>
                            )}
                        </Item>
                    );
                })}
            </ListInner>
        </List>
    );
};

export default ExpandedNavigation;

const Container = styled.div`
    display: grid;
    height: 100%;
    max-width: 160rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) and (max-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        grid-auto-columns: 50%;
        grid-auto-flow: column;

        // weird hack to force gap at the end
        &:after {
            content: '';
            display: block;
            width: 1px;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const TopLevelList = styled.div`
    display: none;

    // medium only
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) and (max-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        display: block;
    }
`;

const ListInner = styled.div`
    position: sticky;
    top: 0;
    flex: 1;
`;

const List = styled.div<{ level: number }>`
    padding: 2.4rem 3.2rem 3.2rem;
    transition: transform 0.3s, opacity 0.3s;
    display: flex;
    align-items: flex-start;
    height: 100%;
    position: relative;

    &::before,
    &::after {
        z-index: 100;
        content: '';
        position: absolute;
        width: 0.1rem;
        top: 0.1rem;
        bottom: 0;
        background: ${({ theme }) => theme.colors.neutral[20]};
    }

    &::before {
        left: 0;
        background: ${({ level, theme }) => (level === -1 ? `${theme.colors.neutral[20]}` : 'none')};
    }

    &::after {
        right: 0;
        background: ${({ theme }) => theme.colors.neutral[20]};
    }

    &.enter {
        transform: translateX(-3.2rem);
        opacity: 0;
    }
    &.enter-active {
        transform: translateX(0);
        opacity: 1;
    }
    &.exit {
        transform: translateX(0);
        opacity: 1;
    }
    &.exit-active {
        transform: translateX(-3.2rem);
        opacity: 0;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        ${({ level }) =>
            // level 0 does the same animation, but vertically.
            level === 0 &&
            css`
                transition: none;
            `}
        padding-bottom: 8rem;

        &::before {
            background: ${({ level, theme }) => (level === 0 ? `${theme.colors.neutral[20]}` : 'none')};
            ${({ level }) =>
                // level 0 does the same animation, but vertically.
                level === 0 &&
                css`
                    transition: none;
                `}
        }
    }
`;

const Item = styled.div`
    display: flex;
    align-items: center;
`;

const ItemLink = styled.a<{ isActive?: boolean }>`
    padding: 0.8rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex: 1;

    &:hover {
        color: ${({ theme }) => theme.colors.primary[60]} !important;
        text-decoration: underline;
    }
    &:focus,
    &:active,
    > * {
        color: inherit !important;
    }

    :after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -3.2rem;
        border-radius: 0 0.4rem 0.4rem 0;
        background: ${({ theme }) => theme.colors.secondary[20]};
        width: 0.4rem;
        transition: opacity 0.33s ease-in, transform 0.33s ease-in;
        transform: translateX(${({ isActive }) => (isActive ? '0' : '-100%')});
        opacity: ${({ isActive }) => +(isActive ?? false)};
    }

    h5 {
        font-weight: ${({ isActive }) => (isActive ? '600' : '500')};
        transition: transform 0.34s ease-in;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 96%;
    }
`;

const ListWrapper = styled.div`
    position: absolute;
    top: 10.3rem;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.colors.neutral['0']};
    transition: transform 0.5s;

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) and (max-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        top: 7.1rem;
    }

    &.enter {
        transform: translateY(-100%);
    }
    &.enter-active {
        transform: translateY(0);
    }
    &.exit {
        transform: translateY(0);
    }
    &.exit-active {
        transform: translateY(-100%);
    }
`;

const StyledNavWrapper = styled(NavWrapper as FC<React.PropsWithChildren<unknown>>)<
    HTMLAttributes<HTMLDivElement> & { ref: RefObject<HTMLDivElement> }
>`
    overflow: auto;
    height: calc(100vh - 7.1rem);

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        max-witdh: 160rem;
        height: auto;
        max-height: calc(70vh + 7.1rem);

        &::after {
            content: '';
            position: absolute;
            width: calc(100% - 0.2rem);
            height: 8rem;
            bottom: 0;
            left: 0.1rem;
            background: linear-gradient(0deg, #ffffff 40%, rgba(255, 255, 255, 0) 100%);
        }
        ::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xxl}) {
        max-height: 73.6rem;
    }
`;

const StyledCaret = styled(CaretRight)`
    width: 0.4rem;
    height: 0.4rem;
`;

const BackCaret = styled(StyledCaret as FC<React.PropsWithChildren<unknown>>)`
    transform: rotate(180deg);
    padding-left: 0;
`;

const ListHeader = styled(Item as FC<React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>>)`
    cursor: pointer;

    > button {
        margin-right: 1.6rem;
    }

    > div {
        justify-content: flex-start;

        &:hover {
            color: inherit !important;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        display: none;
    }
`;

const CloseMenuButton = styled(Button)`
    left: 0;
    margin: 2rem 1rem 0 2rem;
    display: flex;
    z-index: 1000;
    position: absolute;
    flex-direction: row;
    height: 3.6rem;
    padding: 0.4rem 1.6rem;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.colors.neutral[20]}, 0 2px 4px 0 rgba(59, 118, 160, 0.02),
        0 4px 12px 0 rgba(59, 118, 160, 0.04);
    svg {
        margin-right: 1rem;
        width: 1.8rem;
        height: 1.8rem;
        & :hover {
            outline: none;
        }
    }
    & :hover {
        outline: none;
        color: ${({ theme }) => theme.colors.primary[60]};
        box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.colors.neutral[30]};
    }
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.l}) {
        display: none;
    }
`;
