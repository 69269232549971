import { BrandConfig } from '@oberoninternal/travelbase-website/dist/constants/brandConfig';
import { FormattedMessage } from 'react-intl';
import {
    PaymentMethodEnum,
    SearchRentalUnitsOrderEnum,
    UnitSearchOrderEnum,
} from '@oberoninternal/travelbase-website/dist/generated/graphql';
import React from 'react';
import theme from './theme';
import { accommodationTypes, rentalUnitTypes } from '@oberoninternal/travelbase-website/dist/constants/filterTypes';
import fonts from './fonts';
import Whatsapp from '@oberoninternal/travelbase-website/dist/components/svg/socials/Whatsapp.svg';
import ZoTexel from '@oberoninternal/travelbase-website/dist/components/svg/ZoTexel.svg';
import BookLocal from '@oberoninternal/travelbase-website/dist/components/svg/BookLocal.svg';
import Mail from '../components/icons/social/Mail';
import Phone from '../components/icons/social/Phone';
import TranslatedPhoneNumber from '@oberoninternal/travelbase-website/dist/components/TranslatedPhoneNumber';

export const WHATSAPP_NUMBER = '31222314741';
const texelConfig: BrandConfig = {
    name: 'Texel',
    theme,
    gtmID: 'GTM-K73S7L5',
    hasReviews: true,
    initialNewsletterChecked: true,
    font: {
        css: fonts,
    },
    locationDescription: {
        imgSrc: '/static/img/texel.jpg',
        imgAlt: {
            nl: 'Dit is Texel',
            en: 'This is Texel',
            de: 'Das ist Texel',
        },

        tips: {
            nl: [
                'Uitkijkpunt De Fonteinsnol',
                'Een boottocht op het wad',
                'Het Sommeltjespad aan de Pelikaanweg',
                'Wadlopen over de bodem van de zee',
                'Het strand bij de vuurtoren',
            ],
            en: [
                "Viewpoint 'De Fonteinsnol'",
                'A boattrip along the Waddensea (tidal flats)',
                "'Sommeltjespad' at the Pelikaanweg",
                'Mudflat hiking across the bottom of the sea',
                'The beach at the lighthouse',
            ],
            de: [
                'Aussichtspunkt De Fonteinsnol',
                'Eine Bootsfahrt auf dem Wattenmeer',
                'Sommeltjespad am Pelikaanweg',
                'Wattwanderung über den Meeresboden',
                'Der Strand am Leuchtturm',
            ],
        },

        description: {
            nl:
                'Texel is een bruisend eiland.' +
                'Het hele jaar door is er veel te doen: ' +
                'van unieke sportevenementen en culinaire activiteiten tot de leukste muziekfestivals. ' +
                'Natuurlijk is een bezoek aan bekende plekken zoals natuurgebied de Slufter, ' +
                'de Vuurtoren en Ecomare meer dan de moeite waard. ' +
                'Maar er zijn nog veel meer plekken te ontdekken.',
            en: 'Texel is a vibrant island. There are many activities throughout the year. Unique sports events, culinary activities to the best music festivals. Of course a visit to famous scenic spots such as the Slufter nature reserve, the Lighthouse and Ecomare are more than worthwhile.',
            de: `Texel ist eine lebendige Insel. Es gibt viele Aktivitäten während des ganzen Jahres. Von einzigartigen Sportveranstaltungen, kulinarische Aktivitäten zu den besten Musikfestivals. Natürlich ist ein Besuch der berühmten Aussichtspunkte wie die Slufter, der Leuchtturm und Ecomare mehr als lohnenswert.`,
        },
    },

    contact: {
        info: [
            {
                name: <FormattedMessage defaultMessage="Stel je vraag via WhatsApp" />,
                content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                icon: <Whatsapp width={24} height={24} />,
                link: `https://wa.me/${WHATSAPP_NUMBER}`,
                queryParams: {
                    nl: {
                        text: 'Beste VVV Texel, ik heb een vraag over {rentalUnitName}',
                    },
                    de: {
                        text: 'Sehr geehrter Mitarbeiter des VVV Texel, ich habe eine Frage zu {rentalUnitName}',
                    },
                    en: {
                        text: 'Dear VVV Texel employee, I have a question about {rentalUnitName}',
                    },
                },
            },
            {
                name: (
                    <>
                        <FormattedMessage defaultMessage="Bel" />{' '}
                        <TranslatedPhoneNumber phoneNumber="+31 222 314 741" />
                    </>
                ),
                content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                icon: <Phone width={24} height={24} />,
                link: 'tel:+31(0)222314741',
            },

            {
                name: <FormattedMessage defaultMessage="E-mail" />,
                content: <FormattedMessage defaultMessage="Je krijgt binnen twee werkdagen een reactie." />,
                icon: <Mail width={24} height={24} />,
                link: 'mailto:info@texel.net',
                queryParams: {
                    nl: {
                        body: 'Beste VVV Texel, ik heb een vraag over {rentalUnitName}',
                    },
                    de: {
                        body: 'Sehr geehrter Mitarbeiter des VVV Texel, ich habe eine Frage zu {rentalUnitName}',
                    },
                    en: {
                        body: 'Dear VVV Texel employee, I have a question about {rentalUnitName}',
                    },
                },
            },
        ],
        page: {
            nl: 'https://www.texel.net/nl/over-texel/over-ons/contact/',
            en: 'https://www.texel.net/en/about-texel/about-us/contact/',
            de: 'https://www.texel.net/de/ueber-texel/ueber-uns/kontakt/',
        },
    },

    // Map zoom level
    mapZoom: 11,

    // Map min zoom level
    mapMinZoom: {
        desktop: 11,
        mobile: 8.5,
    },

    locales: ['nl', 'en', 'de'],
    accommodationTypes: [
        {
            // not a valid filter, so make sure to escape the value when using it
            message: 'all',
            value: 'all',
        },
        {
            message: 'vacationHomes',
            value: rentalUnitTypes.HOME,
        },
        {
            message: 'hotel',
            value: `${rentalUnitTypes.ROOM} ${accommodationTypes.HOTEL}`,
        },
        {
            message: 'bnb',
            value: `${rentalUnitTypes.ROOM} ${accommodationTypes.BED_AND_BREAKFAST}`,
        },
        {
            message: 'camping',
            value: rentalUnitTypes.CAMPING_PITCH,
        },
        {
            message: 'camperPitch',
            value: 'general.camper-welcome',
        },
        {
            message: 'tent',
            value: rentalUnitTypes.TENT,
        },
    ],
    groupTypes: ['adults', 'children', 'babies', 'pets'],
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RandomBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },

    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RandomBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    defaultTripSearchOrderByRentalUnitType: {
        [rentalUnitTypes.ROOM]: UnitSearchOrderEnum.PriceAsc,
    },
    searchBar: { hide: true },
    coordinates: {
        latitude: 53.081111063258284,
        longitude: 4.801948533617449,
    },

    seo: {
        twitterUsername: '@VVV_Texeltips',
        siteName: 'VVV Texel',
    },
    termsAndConditions: {
        nl: `${process.env.NEXT_PUBLIC_BASE_URL}/nl/over-texel/over-ons/voorwaarden/algemene-boekingsvoorwaarden-van-texel-net/`,
        en: `${process.env.NEXT_PUBLIC_BASE_URL}/en/about-texel/about-us/conditions/general-terms-and-conditions/`,
        de: `${process.env.NEXT_PUBLIC_BASE_URL}/de/ueber-texel/ueber-uns/bedingungen/allgemeine-reisebedingungen-von-vvv-texel/`,
    },

    minBookingAge: 18,
    cancellationInsuranceDefault: false,
    bookButtonVariant: 'secondary',
    surveyUrl: {
        nl: 'https://nl.surveymonkey.com/r/JouwervaringenopTexel',
        en: 'https://www.surveymonkey.com/r/YourexperiencesonTexel',
        de: 'https://de.surveymonkey.com/r/IhreErfahrungenaufTexel',
    },
    checkout: {
        mobileHeaderPlacement: 'bottom',
        initialPaymentMethod: {
            nl: PaymentMethodEnum.Ideal,
            en: PaymentMethodEnum.CreditCard,
            de: PaymentMethodEnum.Paypal,
        },
        insuranceTerms: {
            nl: 'https://d1mgb2rpq3n20q.cloudfront.net/library/PDFs/NL-Uitleg-annuleringsverzekering.pdf',
            en: 'https://d1mgb2rpq3n20q.cloudfront.net/library/PDFs/NL-Uitleg-annuleringsverzekering.pdf',
            de: 'https://d1mgb2rpq3n20q.cloudfront.net/library/PDFs/DE-Uitleg-annuleringsverzekering.pdf',
        },
        showBookingConfirmExtras: true,
        showTrustedShopsCard: true,
    },
    complaintFormUrl: {
        nl: 'https://www.texel.net/nl/over-texel/over-ons/klantenservice/klachtenformulier/',
        en: 'https://www.texel.net/en/about-texel/about-us/customer-service/complaint-form/',
        de: 'https://www.texel.net/de/ueber-texel/ueber-uns/kontakt2/formular-mit-beschwerde/',
    },
    unitExras: {
        svgFilter:
            'brightness(0) saturate(100%) invert(18%) sepia(25%) saturate(1901%) hue-rotate(190deg) brightness(93%) contrast(93%)',
    },
    navigation: {
        offset: -60,
    },
    enableFavorites: true,
    badge: {
        icon: <ZoTexel />,
        handle: 'general.zotexels',
    },
    badge2: {
        icon: <BookLocal />,
        handle: 'general.book-local',
    },
};

export default texelConfig;
