"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSearchDateLabel = exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = _interopRequireWildcard(require("react"));
var _grid = require("@rebass/grid");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Dropdown = _interopRequireWildcard(require("./designsystem/Dropdown"));
var _reactIntl = require("react-intl");
var _ToggleSwitchWithText = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/ToggleSwitchWithText"));
var _DatepickerField = _interopRequireDefault(require("./DatepickerField"));
var _Fieldset = _interopRequireDefault(require("./designsystem/Fieldset"));
var _customizationClassNames = require("../constants/customizationClassNames");
var _formik = require("formik");
var _FlexibleDateContent = _interopRequireDefault(require("./FlexibleDateContent"));
var _parse = _interopRequireDefault(require("date-fns/parse"));
var _dateFns = require("date-fns");
var _useDatepicker = _interopRequireDefault(require("../hooks/useDatepicker"));
var _trip = require("../utils/trip");
var _useOnclickoutside = _interopRequireDefault(require("use-onclickoutside"));
const _excluded = ["open"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getSearchDateLabel = (booking, formatDate) => {
  const parsedFlexibleMonth = booking.flexibleMonth && (0, _parse.default)(booking.flexibleMonth, 'MM-yyyy', new Date());
  switch (booking.type) {
    case 'flexible-weekends':
    case 'flexible-random':
      if (booking.amountOfNights && parsedFlexibleMonth) {
        return __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
          id: "fiVw0j",
          defaultMessage: [{
            "type": 2,
            "style": null,
            "value": "amountOfNights"
          }, {
            "type": 0,
            "value": " "
          }, {
            "type": 6,
            "pluralType": "cardinal",
            "value": "amountOfNights",
            "offset": 0,
            "options": {
              "one": {
                "value": [{
                  "type": 0,
                  "value": "nacht"
                }]
              },
              "other": {
                "value": [{
                  "type": 0,
                  "value": "nachten"
                }]
              }
            }
          }, {
            "type": 0,
            "value": " in "
          }, {
            "type": 1,
            "value": "month"
          }],
          values: {
            amountOfNights: booking.amountOfNights,
            month: formatDate(parsedFlexibleMonth, _objectSpread({
              month: 'long'
            }, !(0, _dateFns.isSameYear)(new Date(), parsedFlexibleMonth) ? {
              year: 'numeric'
            } : {}))
          }
        }));
      }
      return defaultLabel;
    case 'static':
    default:
      if (booking.arrivalDate && booking.departureDate) {
        return __jsx("span", null, formatDate(booking.arrivalDate, {
          format: 'DATERANGEPICKER'
        }), ' ', "-", ' ', formatDate(booking.departureDate, {
          format: 'DATERANGEPICKER'
        }));
      }
      return defaultLabel;
  }
};
exports.getSearchDateLabel = getSearchDateLabel;
const SearchDateField = ({
  isHomepage,
  hideFlexibleOptions,
  variant = 'field'
}) => {
  const [, startMeta] = (0, _formik.useField)('booking.arrivalDate');
  const [, endMeta] = (0, _formik.useField)('booking.departureDate');
  const initialValue = (0, _trip.convertToPeriod)(startMeta.initialValue, endMeta.initialValue);
  const {
    values,
    setFieldValue
  } = (0, _formik.useFormikContext)();
  const {
    formatDate
  } = (0, _reactIntl.useIntl)();
  const datepicker = (0, _useDatepicker.default)({
    singleDate: false,
    optionalSingleDate: false,
    period: initialValue,
    open: false
  }, variant === 'inline');
  const [_ref, {
    set
  }] = datepicker;
  const {
      open
    } = _ref,
    state = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const onToggle = (0, _react.useCallback)(() => {
    set(_objectSpread(_objectSpread({}, state), {}, {
      open: !open,
      focus: !open ? 'start' : undefined
    }));
  }, [set, state, open]);
  const ref = (0, _react.useRef)(null);
  (0, _useOnclickoutside.default)(ref, () => open && onToggle());
  const renderInputs = () => __jsx(_react.default.Fragment, null, !hideFlexibleOptions && __jsx(_grid.Flex, {
    py: [16, null, variant === 'field' ? 0 : 16],
    px: 16,
    justifyContent: "center",
    className: "toggleSwitchContainer"
  }, __jsx(_ToggleSwitchWithText.default, {
    name: "dateSwitch",
    active: values.booking.type === 'static' ? 'static' : 'flexible',
    setActive: key => setFieldValue('booking.type', key === 'flexible' ? 'flexible-random' : key),
    options: [{
      key: 'static',
      label: __jsx(_reactIntl.FormattedMessage, {
        id: "S7bCwP",
        defaultMessage: [{
          "type": 0,
          "value": "Datums kiezen"
        }]
      })
    }, {
      key: 'flexible',
      label: __jsx(_reactIntl.FormattedMessage, {
        id: "VNRBWz",
        defaultMessage: [{
          "type": 0,
          "value": "Flexibel zoeken"
        }]
      })
    }]
  })), (values.booking.type === 'static' || hideFlexibleOptions) && __jsx(_Fieldset.default, {
    label: "",
    dataCy: "searchFormDates",
    className: _customizationClassNames.CLASS_SEARCH_BOX_FIELDSET
  }, __jsx(_DatepickerField.default, {
    scrollIntoView: false,
    startName: "booking.arrivalDate",
    endName: "booking.departureDate",
    hideInput: true,
    hasFlexibleDates: true,
    datepicker: datepicker,
    singleDate: false,
    ignoreClickOutside: true,
    variant: variant
  })), values.booking.type !== 'static' && !hideFlexibleOptions && __jsx(_FlexibleDateContent.default, null));
  return variant === 'field' ? __jsx(StyledDropdown, {
    borderRadius: 0,
    popupPlacement: isHomepage ? 'left' : 'bottom-start',
    rounded: isHomepage,
    modifiers: {
      flip: {
        enabled: false
      },
      // custom computeStyle function to center the popper
      computeStyle: {
        enabled: true,
        fn(data) {
          // eslint-disable-next-line no-param-reassign
          data.styles = _objectSpread(_objectSpread({}, data.styles), {}, {
            position: 'absolute',
            willChange: 'transform',
            top: '0px',
            left: '-50%',
            transform: 'translate3d(0px, 58px, 0px)'
          });
          return data;
        }
      }
    },
    surfaceElement: __jsx(_grid.Flex, {
      flexWrap: "wrap"
    }, !isHomepage && __jsx(_grid.Box, {
      width: 1
    }, __jsx(StyledBody, {
      variant: isHomepage ? 'regular' : 'tiny'
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "ANwe4i",
      defaultMessage: [{
        "type": 0,
        "value": "Reisperiode"
      }]
    }))), __jsx(_grid.Box, {
      width: 1
    }, __jsx(_grid.Box, null, __jsx(_Dropdown.DropdownDescription, {
      variant: isHomepage ? 'regular' : 'tiny'
    }, getSearchDateLabel(values.booking, formatDate))))),
    open: open,
    onToggle: onToggle,
    ref: ref
  }, __jsx(_grid.Box, {
    py: [3, 5],
    width: ['100%', '70rem', '70rem', '80rem'],
    style: {
      maxWidth: '100vw',
      textAlign: 'center'
    }
  }, renderInputs())) : renderInputs();
};
var _default = exports.default = SearchDateField;
const StyledBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "SearchDateField__StyledBody",
  componentId: "sc-19lsdu1-0"
})(["color:", ";font-weight:300 !important;line-height:1.15;white-space:nowrap;text-align:left;"], ({
  theme
}) => theme.colors.neutral['60']);
const StyledDropdown = (0, _styledComponents.default)(_Dropdown.default).withConfig({
  displayName: "SearchDateField__StyledDropdown",
  componentId: "sc-19lsdu1-1"
})(["--borderRadius:", ";"], ({
  theme,
  rounded
}) => rounded && theme.radius.textInput);
const defaultLabel = __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
  id: "AS4zvR",
  defaultMessage: [{
    "type": 0,
    "value": "Kies reisperiode"
  }]
}));