import React, { Dispatch, FC, HTMLAttributes, SetStateAction } from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import {
    MenuItemPageFieldsFragment,
    MenuItemPageRecursiveFragment,
    MenuItemsFragment,
    Scalars,
} from '../generated/graphql';
import Submenu from './Submenu';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LinkField from './LinkField';
import BackButton from './NavButton';
import CaretRight from './icons/CaretRight';
import Link from './Link';
import { FormattedMessage, useIntl } from 'react-intl';
import getContactLink from '../utils/getContactLink';
import { useRouter } from 'next/router';
import { isActiveMenuItem, MenuItemTitle } from './Header';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';

export const fragment = gql`
    fragment MenuItems on menu_Entry {
        id
        title

        menuLink {
            ...LinkField
            element {
                ...MenuItemPageFields
                ...MenuItemPageRecursive
            }
        }
    }

    fragment MenuItemPageRecursive on page_Entry {
        # Although this looks ugly as hell, it's actually what GQL recommends you to do! :(
        # https://github.com/graphql/graphql-spec/issues/91#issuecomment-254895093
        children {
            ...MenuItemPageFields
            children {
                ...MenuItemPageFields
                children {
                    ...MenuItemPageFields
                    children {
                        ...MenuItemPageFields
                    }
                }
            }
        }
    }

    fragment MenuItemPageFields on page_Entry {
        slug
        id
        title
        uri
        settingsMenuHidden
        settingsMenuTitle
        parent {
            uri
            id
        }
    }
`;

interface Props {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    menu?: MenuItemsFragment[];
    selectedPageIds: EntryIds;
    setSelectedPageIds: Dispatch<SetStateAction<EntryIds>>;
}

// convenience types
export type EntryIds = Array<Scalars['ID']>;
export type MenuItemPageFragment = MenuItemPageRecursiveFragment & MenuItemPageFieldsFragment;

const MainNavigation: FC<React.PropsWithChildren<Props>> = ({
    open,
    menu,
    selectedPageIds,
    setSelectedPageIds,
    setOpen,
}: Props) => {
    const { locale } = useIntl();
    const { asPath } = useRouter();
    const deviceSize = useDeviceSize();

    return (
        <CSSTransition timeout={500} in={open}>
            <Outer open={open}>
                <Inner open={open}>
                    <NavigationList>
                        {menu?.map(item => {
                            const menuEntry = item.menuLink?.[0]?.element;
                            const hasChildren =
                                menuEntry?.__typename === 'page_Entry' ? (menuEntry.children?.length ?? 0) > 0 : false;
                            const isActive =
                                menuEntry?.__typename === 'page_Entry' ? selectedPageIds[0] === menuEntry.id : false;

                            return (
                                item?.menuLink?.[0] && (
                                    <MenuItem key={item?.id} isActive={isActive}>
                                        <LinkField
                                            link={item.menuLink?.[0]}
                                            onMouseEnter={() => {
                                                if (menuEntry?.__typename !== 'page_Entry') {
                                                    if (deviceSize !== 'mobile') {
                                                        setOpen(false);
                                                        setSelectedPageIds([]);
                                                    }
                                                    return;
                                                }
                                                if (hasChildren && menuEntry.id) {
                                                    setOpen(true);
                                                    setSelectedPageIds([menuEntry.id]);
                                                } else {
                                                    setOpen(false);
                                                    setSelectedPageIds([]);
                                                }
                                            }}
                                            onClick={() => {
                                                setOpen(false);
                                                setSelectedPageIds([]);
                                            }}
                                        >
                                            <MenuItemTitle
                                                highlight={isActiveMenuItem(
                                                    asPath,
                                                    item.menuLink[0].element?.uri ?? ''
                                                )}
                                            >
                                                {item?.title}
                                            </MenuItemTitle>
                                        </LinkField>

                                        {hasChildren && menuEntry?.__typename === 'page_Entry' && (
                                            <StyledBackButton
                                                onClick={() => {
                                                    if (hasChildren && menuEntry.id) {
                                                        setOpen(true);
                                                        setSelectedPageIds([menuEntry.id]);
                                                    }
                                                }}
                                            >
                                                <CaretRight />
                                            </StyledBackButton>
                                        )}

                                        <TransitionGroup>
                                            {menuEntry?.__typename === 'page_Entry' &&
                                                selectedPageIds[0] === menuEntry.id && (
                                                    <CSSTransition timeout={500}>
                                                        <Submenu
                                                            closeMenu={() => setOpen(false)}
                                                            level={0}
                                                            selectedPageIds={selectedPageIds}
                                                            setSelectedPageIds={setSelectedPageIds}
                                                            item={menuEntry}
                                                        />
                                                    </CSSTransition>
                                                )}
                                        </TransitionGroup>
                                    </MenuItem>
                                )
                            );
                        })}
                        <MenuItem
                            onMouseEnter={() => {
                                if (deviceSize !== 'mobile') {
                                    setOpen(false);
                                    setSelectedPageIds([]);
                                }
                                return;
                            }}
                        >
                            <Link href={`/${getContactLink(locale)}`}>
                                <a>
                                    <MenuItemTitle highlight={isActiveMenuItem(asPath, getContactLink(locale) ?? '')}>
                                        <FormattedMessage defaultMessage="Contact" />
                                    </MenuItemTitle>
                                </a>
                            </Link>
                        </MenuItem>
                    </NavigationList>
                </Inner>
            </Outer>
        </CSSTransition>
    );
};

export default MainNavigation;

const StyledBackButton = styled(BackButton as FC<React.PropsWithChildren<unknown>>)<HTMLAttributes<HTMLDivElement>>`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }
`;

const Outer = styled.div<{ open: boolean }>`
    position: absolute;
    right: 0;
    left: 0;
    top: 7.2rem;
    height: calc(100vh - 7.2rem);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10;
    pointer-events: ${({ open }) => (open ? `unset` : `none`)};

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        &.enter > nav {
            transform: translateX(100vw);
        }
        &.enter-active > nav {
            transform: translateX(0);
        }
        &.exit > nav {
            transform: translateX(0);
        }
        &.exit-active > nav {
            transform: translateX(100vw);
        }
        &.exit-done > nav {
            display: none;
        }
        &.enter-done > nav {
            display: block;
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        position: relative;
        overflow-x: auto;
        height: auto;
        top: 0;
        pointer-events: unset;
    }
`;

const Inner = styled.nav<{ open: boolean }>`
    ${({ open }) => (open ? `transform: translateX(0)` : `transform: translateX(100vw)`)};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transition: transform 0.5s ease-in-out;
    background: ${({ theme }) => theme.colors.neutral[0]};
    padding: 2.2rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        opacity: 1;
        display: flex;
        position: relative;
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
        padding: 0;
        transform: none;
        transition: none;
        height: auto;
        top: 0;
        box-shadow: none;
    }
`;

const NavigationList = styled.ul`
    margin: 0;
    padding: 0;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) and (max-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        display: none;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        display: flex;
        width: 100%;
    }
`;

export const MenuItem = styled.li<{ isActive?: boolean }>`
    line-height: 1;
    margin: 0;
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;

    > a {
        padding: 1.2rem 0;
        margin: 0 1.6rem 0 0;
        line-height: 1.6rem;
        text-decoration: none !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${({ theme }) => theme.colors.primary[80]} !important;
        flex: 1;

        &:hover,
        &:focus {
            color: ${({ theme }) => theme.colors.primary[60]} !important;
        }
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 8rem);
        display: block;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        font-size: 1.6rem;
        font-weight: 800;

        &:after {
            content: '';
            bottom: 0;
            left: 1.6rem;
            right: 1.6rem;
            background: ${({ theme }) => theme.colors.secondary['20']};
            border-radius: 12.4rem 12.4rem 0 0;
            height: 0.6rem;
            display: block;
            position: absolute;
            transition: opacity 0.25s;
            opacity: ${({ isActive }) => +(isActive ?? false)};
        }
        &:first-child {
            margin-left: -1.6rem;
        }
        > a {
            padding: 4.4rem 1.6rem;
            margin: 0;
        }
    }
`;
