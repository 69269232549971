"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCheckoutPages = exports.useCheckoutPageMeta = exports.hasDeviceId = exports.getSuffixForRoute = void 0;
var _router = require("next/router");
var _reactIntl = require("react-intl");
var _storage = require("../constants/storage");
var _checkout = require("../context/checkout");
const getSuffixForRoute = route => {
  switch (route) {
    case 'CheckoutExtras':
      return 'checkout/extras';
    case 'CheckoutDetails':
      return 'checkout/details';
    case 'CheckoutPayment':
      return 'checkout/payment';
    case 'CheckoutConfirm':
      return 'checkout/confirm';
    default:
      return '';
  }
};
exports.getSuffixForRoute = getSuffixForRoute;
function assertIsCheckoutRoute(pages, activeRoute) {
  const isCheckoutPage = !!pages.find(({
    suffix
  }) => activeRoute.endsWith(suffix));
  if (!isCheckoutPage) {
    throw new Error('useCheckoutPages must be used within the specified routes');
  }
}
const getActive = (pages, activeRoute) => {
  assertIsCheckoutRoute(pages, activeRoute);
  const activePage = pages.find(({
    suffix
  }) => activeRoute.endsWith(suffix));
  const activeStep = pages.findIndex(({
    suffix
  }) => activeRoute.endsWith(suffix));
  if (!activePage) {
    throw new Error("There isn't any checkout page active ");
  }
  return {
    activePage,
    activeStep
  };
};
const pageTranslations = (0, _reactIntl.defineMessages)({
  CheckoutExtras: {
    "id": "OHWyTr",
    "defaultMessage": [{
      "type": 0,
      "value": "Boeking"
    }]
  },
  CheckoutDetails: {
    "id": "tYrxWP",
    "defaultMessage": [{
      "type": 0,
      "value": "Gegevens"
    }]
  },
  CheckoutPayment: {
    "id": "aTEVss",
    "defaultMessage": [{
      "type": 0,
      "value": "Betaling"
    }]
  },
  CheckoutWithoutPayment: {
    "id": "IkZysu",
    "defaultMessage": [{
      "type": 0,
      "value": "Samenvatting"
    }]
  },
  CheckoutRequest: {
    "id": "cWhOt+",
    "defaultMessage": [{
      "type": 0,
      "value": "Aanvragen"
    }]
  },
  CheckoutConfirm: {
    "id": "0UDQ5J",
    "defaultMessage": [{
      "type": 0,
      "value": "Bevestiging"
    }]
  }
});
const hasDeviceId = () => localStorage.getItem(_storage.DEVICE_ID) !== null;

// TODO: this needs to be improved and should be derived from the Cart entirely
exports.hasDeviceId = hasDeviceId;
const useCheckoutPages = (checkoutType, onlyTicketsInBasket) => {
  const {
    variant
  } = (0, _checkout.useCheckoutState)();
  const {
    formatMessage
  } = (0, _reactIntl.useIntl)();
  return _checkout.variantMap[variant].map(route => {
    if (checkoutType && route === 'CheckoutPayment') {
      return {
        route,
        suffix: getSuffixForRoute(route),
        title: formatMessage(pageTranslations[checkoutType])
      };
    }

    // bleghhh
    if (route === 'CheckoutExtras' && onlyTicketsInBasket) {
      return {
        route,
        suffix: getSuffixForRoute(route),
        title: formatMessage({
          "id": "o3H3Tu",
          "defaultMessage": [{
            "type": 0,
            "value": "Overzicht"
          }]
        })
      };
    }
    return {
      route,
      suffix: getSuffixForRoute(route),
      title: formatMessage(pageTranslations[route])
    };
  });
};
exports.useCheckoutPages = useCheckoutPages;
const useCheckoutPageMeta = () => {
  const {
    pathname
  } = (0, _router.useRouter)();
  const activeRoute = pathname.replace('/', '');
  const pages = useCheckoutPages();
  const {
    activePage,
    activeStep
  } = getActive(pages, activeRoute);
  const nextPage = pages[activeStep + 1]; // It could also be undefined when there's no next page
  return {
    activePage,
    activeStep,
    nextPage
  };
};
exports.useCheckoutPageMeta = useCheckoutPageMeta;