import { gql } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { FooterItemsFragment } from '../generated/graphql';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Facebook from './icons/social/Facebook';
import Icon from './Icon';
import Instagram from './icons/social/Instagram';
import Youtube from './icons/social/Youtube';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Page from '@oberoninternal/travelbase-website/dist/components/Page';
import IDeal from './icons/logos/IDeal';
import Paypal from './icons/logos/Paypal';
import VVV from './icons/social/VVV';
import LinkField from './LinkField';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import HanseMerkur from './icons/logos/HanseMerkur';

export const fragment = gql`
    fragment FooterItems on footerCategory_Entry {
        title
        children {
            ...FooterSubmenu
        }
    }

    fragment FooterSubmenu on footerLink_Entry {
        title
        __typename
        menuLink {
            ...LinkField
        }
    }
`;

interface Props {
    footer: FooterItemsFragment[];
}

const Footer: FC<React.PropsWithChildren<Props>> = ({ footer }: Props) => {
    const footerItems = [...footer];
    const lastItems = footerItems.pop();
    const { formatMessage } = useIntl();
    const [stickybarActive, setStickybarActive] = useState(false);
    const router = useRouter();
    useEffect(() => {
        setStickybarActive(!!document.getElementsByClassName('travelbase-stickybar').length);
    }, [router.pathname]);

    return (
        <Page>
            <FooterWrapper style={{ paddingBottom: stickybarActive ? 'var(--booking-bar-height)' : undefined }}>
                <ContentWrapper>
                    <FooterMenu>
                        {footerItems.map((item, key) => (
                            <FooterColumn key={key}>
                                <SubmenuTitle variant="tiny" elementType="h2">
                                    {item.title}
                                </SubmenuTitle>
                                <SubmenuList>
                                    {item.children?.map((submenuItem, i) => {
                                        if (!submenuItem || submenuItem?.__typename !== 'footerLink_Entry') {
                                            return null;
                                        }

                                        return (
                                            submenuItem?.menuLink?.[0] &&
                                            submenuItem.title && (
                                                <SubmenuListItem key={i}>
                                                    <LinkField link={submenuItem.menuLink[0]}>
                                                        {submenuItem.title}
                                                    </LinkField>
                                                </SubmenuListItem>
                                            )
                                        );
                                    })}
                                </SubmenuList>
                            </FooterColumn>
                        ))}
                        <FooterColumn last>
                            <SubmenuTitle variant="tiny" elementType="h2">
                                <FormattedMessage defaultMessage="Volg ons" />
                            </SubmenuTitle>
                            <Social>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={formatMessage({
                                        id: 'facebook',
                                        defaultMessage: 'https://www.facebook.com/WeLoveTexel',
                                    })}
                                    aria-label="Facebook"
                                >
                                    <StyledIcon icon={<StyledFacebook />} rounded />
                                </a>

                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/vvvtexel/"
                                    aria-label="Instagram"
                                >
                                    <StyledIcon icon={<Instagram />} rounded />
                                </a>

                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.youtube.com/user/VVVTexel"
                                    aria-label="Youtube"
                                >
                                    <StyledIcon icon={<Youtube />} rounded />
                                </a>
                            </Social>
                            <Box pt={6}>
                                <LogoTitle variant="tiny" elementType="h2">
                                    <FormattedMessage defaultMessage="Zeker op vakantie" />
                                </LogoTitle>
                                <LogoWrapper>
                                    <Paypal />
                                    <IDeal />
                                    <VVV />
                                    <HanseMerkur />
                                </LogoWrapper>
                            </Box>
                        </FooterColumn>
                    </FooterMenu>
                </ContentWrapper>

                <StyledHr />
                <ContentWrapper>
                    <LastFooter>
                        <SourcesMenu>
                            {lastItems?.children?.map((item, key) => {
                                if (!item || item?.__typename !== 'footerLink_Entry') {
                                    return null;
                                }

                                return (
                                    item?.menuLink?.[0] &&
                                    item.title && (
                                        <LinkField link={item.menuLink[0]} key={key}>
                                            {item.title}
                                        </LinkField>
                                    )
                                );
                            })}
                        </SourcesMenu>
                        <Body>© {new Date().getFullYear()} VVV Texel </Body>
                    </LastFooter>
                </ContentWrapper>
            </FooterWrapper>
        </Page>
    );
};

export default Footer;

const FooterWrapper = styled.footer`
    background-color: ${({ theme }) => theme.colors.primary['90']};
`;

const FooterMenu = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
        padding: 3.2rem 0;
    }
`;

const FooterColumn = styled.div<{ last?: boolean }>`
    display: block;
    padding: 4rem 2.4rem 3.2rem 0;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.primary['80']};
    ${({ last }) => last && `border-bottom: 0 solid transparent`};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        ${({ last }) => (last ? `padding-left: 3.2rem; padding-right: 0;` : `width: calc(29% - 3.2rem);`)};
        border-bottom: 0 solid transparent;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        ${({ last }) => (last ? `padding-left: 15%; width: 33%;` : `width: 25%;`)};
    }
`;

const SubmenuTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.neutral['0']};
    margin-bottom: 2.4rem;
`;

const Social = styled.div`
    display: flex;
    padding-bottom: 3rem;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.primary['80']};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        border-bottom: 0 solid transparent;
    }
`;

const SubmenuList = styled.ul`
    margin: 0;
    padding: 0;
`;

const SubmenuListItem = styled.li`
    margin: 0.2rem 0;

    a {
        color: ${({ theme }) => theme.colors.neutral['0']} !important;
        text-decoration: none !important;
        font-weight: 400 !important;
        font-size: 14px;
        &:hover {
            text-decoration: underline !important;
        }
    }
`;

const StyledFacebook = styled(Facebook)`
    width: 0.9rem;
    height: 1.6rem;
`;

const LastFooter = styled.div`
    color: ${({ theme }) => theme.colors.neutral['30']};
    padding: 4.5rem 0;

    a {
        color: ${({ theme }) => theme.colors.neutral['0']};
        text-decoration: none;
        border-bottom: 0.1rem solid ${({ theme }) => theme.colors.neutral['0']};
        font-weight: 600;
        font-size: 14px;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
        justify-content: space-between;
    }
`;

const StyledHr = styled.hr`
    border-top: 0.1rem solid ${({ theme }) => theme.colors.primary['80']};
    border-bottom: none;
    border-right: none;
    border-left: none;
`;

const SourcesMenu = styled.div`
    padding-bottom: 2.3rem;

    > * + * {
        margin-left: 3.2rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-bottom: 0;
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    color: ${({ theme }) => theme.colors.neutral['0']};

    svg {
        height: 2.4rem;
        margin: 2.4rem 2.4rem 0 0;

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
            margin: 2.4rem 1.6rem 0 0;
        }
    }
`;

const LogoTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.neutral['0']};
`;

const StyledIcon = styled(Icon)`
    margin-right: 2rem;
`;
