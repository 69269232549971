"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _cssCalcBetweenViewport = _interopRequireDefault(require("../utils/cssCalcBetweenViewport"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const searchListCss = (0, _styledComponents.css)(["max-width:none;width:auto;box-sizing:content-box;padding:0 ", ";position:relative;"], (0, _cssCalcBetweenViewport.default)(24, 76));
const getCss = variant => {
  switch (variant) {
    case 'checkout':
      return (0, _styledComponents.css)(["--vertical-padding:7rem;@media screen and (min-width:", "){padding-top:var(--vertical-padding);}padding-top:10rem;padding-bottom:var(--vertical-padding);display:flex;width:100%;"], ({
        theme
      }) => theme.mediaQueries.m);
    case 'left':
      return (0, _styledComponents.css)(["padding-left:var(--wrapperPadding);@media (min-width:", "){margin-left:calc((100vw - var(--max-viewport) * 1px) / 2 - var(--wrapperPadding));margin-right:0;}"], ({
        theme
      }) => theme.mediaQueries.xl);
    case 'continuous':
      return (0, _styledComponents.css)(["padding:0;"]);
    case 'embed':
      return (0, _styledComponents.css)(["padding:0;max-width:100%;margin:0;position:relative;"]);
    case 'searchList':
      return searchListCss;
    case 'searchMap':
      return (0, _styledComponents.css)(["", ";height:100vh;position:relative;@media (max-width:", "px){padding:0;}@media (min-width:", "){height:auto;}"], searchListCss, ({
        theme
      }) => theme.mediaQueriesValues.m - 1, ({
        theme
      }) => theme.mediaQueries.m);
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(variant);
  }
};

/**
 * Should be used in combination with the Page component
 */
const ContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "ContentWrapper",
  componentId: "sc-4e7ly1-0"
})(["max-width:calc(140.8rem + var(--wrapperPadding) * 2);width:100%;margin:0 auto ", ";@media screen and (min-width:", "){margin-bottom:0;}padding:0 var(--wrapperPadding);box-sizing:border-box;", ";"], ({
  theme
}) => theme.spacing['60_Large'], ({
  theme
}) => theme.mediaQueries.s, ({
  variant
}) => variant && getCss(variant));
var _default = exports.default = ContentWrapper;