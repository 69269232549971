"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSendEvent = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// OPTION TYPES

// CALL BACK TYPES

// export interface PlannerEmbedCallbacks {
//     /**
//      * Triggered when no availability was found for the next 3 months.
//      */
//     onNoAvailability?: () => void;
// }

// EVENT TYPES

// HOOKS

const useSendEvent = () => (0, _react.useCallback)(event => {
  window.parent.postMessage(event, '*');
}, []);
exports.useSendEvent = useSendEvent;
const useEmbedEvents = () => {
  const {
    0: searchEmbedOptions,
    1: setSearchEmbedOptions
  } = (0, _react.useState)({
    searchParams: '',
    viewType: 'list',
    enableFilters: true,
    enableSearch: true,
    enableSort: true,
    enableViewType: true
  });
  const {
    0: plannerEmbedOptions,
    1: setPlannerEmbedOptions
  } = (0, _react.useState)(null);
  const sendEvent = useSendEvent();
  const onMessage = e => {
    const event = e.data;
    if (event.source !== 'travelbase') {
      return;
    }
    switch (event.type) {
      case 'setSearchEmbedOptions':
        {
          setSearchEmbedOptions(opts => _objectSpread(_objectSpread({}, opts), event.options));
          break;
        }
      case 'setPlannerEmbedOptions':
        {
          setPlannerEmbedOptions(event.options);
          break;
        }
      default:
        break;
    }
  };
  const triggerSearchCallback = (name, ...args) => {
    sendEvent({
      type: 'triggerSearchCallback',
      name,
      args,
      source: 'travelbase'
    });
  };
  const triggerSearchBoxCallback = (name, ...args) => {
    sendEvent({
      type: 'triggerSearchBoxCallback',
      name,
      args,
      source: 'travelbase'
    });
  };
  (0, _react.useEffect)(() => {
    window.addEventListener('message', onMessage);
    sendEvent({
      type: 'iframeReady',
      source: 'travelbase'
    });
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [sendEvent]);
  (0, _react.useEffect)(() => {
    const targetNode = document.body;
    const callback = () => {
      const rect = document.body.getBoundingClientRect();
      sendEvent({
        type: 'iframeDimensions',
        dimensions: {
          width: rect.width,
          height: rect.height,
          scrollHeight: document.body.scrollHeight,
          scrollWidth: document.body.scrollWidth
        },
        source: 'travelbase'
      });
    };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, {
      childList: true,
      subtree: true,
      attributes: true
    });
    return () => {
      observer.disconnect();
    };
  }, [sendEvent]);
  return {
    sendEvent,
    searchEmbedOptions,
    plannerEmbedOptions,
    triggerSearchCallback,
    triggerSearchBoxCallback
  };
};
var _default = exports.default = useEmbedEvents;